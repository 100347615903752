import useComposedClassName from '@rapid-platform/use-composed-class-name';
import React from 'react';
import { toggleTheme, useStore } from './styles/store/store';
export default function App() {
    const [snap] = useStore();
    const iconClass = useComposedClassName(function* () {
        yield 'fal fa-fw';
        if (snap.ui.theme === 'light-theme') {
            yield 'fa-moon-stars';
        }
        if (snap.ui.theme === 'dark-theme') {
            yield 'fa-sun';
        }
    }, [snap.ui.theme]);
    const themeToggleTitle = useComposedClassName(function* () {
        if (snap.ui.theme === 'dark-theme') {
            yield 'Switch to light theme';
        }
        if (snap.ui.theme === 'light-theme') {
            yield 'Switch to dark theme';
        }
    }, [snap.ui.theme]);
    return (React.createElement("main", { className: "app" },
        React.createElement("button", { className: "theme text primary", title: themeToggleTitle, onClick: toggleTheme },
            React.createElement("i", { className: iconClass })),
        React.createElement("menu", { className: "links" },
            React.createElement("li", { className: "link github" },
                React.createElement("a", { className: "list-link", href: "https://github.com/thealexclub", target: "_blank" },
                    React.createElement("i", { className: "fab fa-fw fa-github" }))),
            React.createElement("li", { className: "link instagram" },
                React.createElement("a", { className: "list-link", href: "https://www.instagram.com/alexisthebestyeah", target: "_blank" },
                    React.createElement("i", { className: "fab fa-fw fa-instagram" })))),
        React.createElement("h1", { className: "title" },
            React.createElement("span", { className: "red" }, "the"),
            React.createElement("span", { className: "yellow" }, "alex"),
            React.createElement("span", { className: "blue" }, "club")),
        React.createElement("div", { className: "email" },
            React.createElement("h4", { className: "email-label" }, "contact"),
            React.createElement("a", { className: "email-link", href: "mailto:enter@thealex.club" }, "enter@thealex.club")),
        React.createElement("div", { className: "work" },
            React.createElement("h4", { className: "work-label" }, "employer"),
            React.createElement("a", { className: "work-link", href: "https://rapidplatform.com.au" }, "rapidplatform.com"))));
}
