import { proxy, subscribe, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';
/*
  TODO: Add in something to check the users prefered theme

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
}

*/
/**
 * Emun used to index themes collection
 *
 */
export var Theme;
(function (Theme) {
    Theme[Theme["Dark"] = 0] = "Dark";
    Theme[Theme["Light"] = 1] = "Light";
})(Theme || (Theme = {}));
/**
 * Collection of allowed themes
 *
 * As an array so we can use it easily in a type guard
 *
 * We have `as const` after it so we can pull each value out to use in a type
 */
export const themes = ['dark-theme', 'light-theme'];
const THEME_KEY = 'adaptive-designer:theme';
const store = proxy({
    ui: {
        theme: themes[Theme.Dark],
    },
});
/**
 * hook to directly access the base store
 */
export function useStore() {
    const snap = useSnapshot(store);
    return [snap, store];
}
/**
 * Directly set the store theme from anywhere
 */
export function setTheme(theme) {
    store.ui.theme = theme;
}
/**
 * Toggle the store theme from anywhere
 */
export function toggleTheme() {
    if (store.ui.theme === themes[Theme.Dark]) {
        store.ui.theme = themes[Theme.Light];
    }
    else {
        store.ui.theme = themes[Theme.Dark];
    }
}
const enabled = process.env.DEVTOOLS === 'true' ?? false;
console.log('Devtools Enabled', enabled);
devtools(store, { name: 'The Alex Club Root Store', enabled });
export default store;
export function getLocalStorageTheme() {
    // Get the theme from local storage
    const currentTheme = localStorage.getItem(THEME_KEY);
    console.log('Current Theme', currentTheme);
    // Check if it's set and use it if it is
    if (typeof currentTheme === 'string') {
        changeDomTheme(currentTheme);
        store.ui.theme = currentTheme;
    }
}
function changeDomTheme(theme) {
    const html = document.getElementsByTagName('html')?.[0];
    // Make sure we have html
    if (!html) {
        return;
    }
    // Check if the theme is already set
    if (html.classList.contains(theme)) {
        return;
    }
    // Set the theme to the appropriate theme
    if (theme === 'dark-theme') {
        html.classList.add('dark-theme');
        html.classList.remove('light-theme');
    }
    else {
        html.classList.add('light-theme');
        html.classList.remove('dark-theme');
    }
    // Add the current theme to localStorage
    localStorage.setItem(THEME_KEY, theme);
}
subscribe(store.ui, () => {
    changeDomTheme(store.ui.theme);
});
